
    import { defineAsyncComponent } from '#imports'
    
import BlokkliComponent_contact_box_generic_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/ContactBoxGeneric/index.vue'
import BlokkliComponent_footer_section_contact_details_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/FooterSectionContactDetails/index.vue'
import BlokkliComponent_footer_section_links_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/FooterSectionLinks/index.vue'
import BlokkliComponent_footer_section_metalinks_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/FooterSectionMetalinks/index.vue'
import BlokkliComponent_footer_section_social_media_link_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/FooterSectionSocialMediaLink/index.vue'
import BlokkliComponent_table_markup_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/TableMarkup/index.vue'
import BlokkliComponent_from_library_index from '/builds/bazinga/pharmasuisse/frontend/node_modules/blokkli-beta/dist/runtime/components/Blocks/FromLibrary/index.vue'
import BlokkliComponent_accordion_element_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/Accordion/index.vue'
import BlokkliComponent_chart_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/Chart/index.vue'
import BlokkliComponent_button_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/Button/index.vue'
import BlokkliComponent_contact_box_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/ContactBox/index.vue'
import BlokkliComponent_container_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/Container/index.vue'
import BlokkliComponent_editorial_title_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/EditorialTitle/index.vue'
import BlokkliComponent_fact_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/Fact/index.vue'
import BlokkliComponent_grid_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/Grid/index.vue'
import BlokkliComponent_image_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/Image/index.vue'
import BlokkliComponent_link_box_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/LinkBox/index.vue'
import BlokkliComponent_links_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/Links/index.vue'
import BlokkliComponent_mailxpert_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/Mailxpert/index.vue'
import BlokkliComponent_node_teaser_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/NodeTeaser/index.vue'
import BlokkliComponent_person_box_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/PersonBox/index.vue'
import BlokkliComponent_product_teaser_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/ProductTeaser/index.vue'
import BlokkliComponent_section_title_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/SectionTitle/index.vue'
import BlokkliComponent_table_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/Table/index.vue'
import BlokkliComponent_table_row_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/TableRow/index.vue'
import BlokkliComponent_teaser_box_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/TeaserBox/index.vue'
import BlokkliComponent_text_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/Text/index.vue'
import BlokkliComponent_video_remote_index from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/VideoRemote/index.vue'
import BlokkliComponent_blokkli_fragment_index from '/builds/bazinga/pharmasuisse/frontend/node_modules/blokkli-beta/dist/runtime/components/Blocks/Fragment/index.vue'

const global: Record<string, any> = {
  block_contact_box_generic: BlokkliComponent_contact_box_generic_index,
  block_footer_section_contact_details: BlokkliComponent_footer_section_contact_details_index,
  block_footer_section_links: BlokkliComponent_footer_section_links_index,
  block_footer_section_metalinks: BlokkliComponent_footer_section_metalinks_index,
  block_footer_section_social_media_link: BlokkliComponent_footer_section_social_media_link_index,
  block_table_markup: BlokkliComponent_table_markup_index,
  block_from_library: BlokkliComponent_from_library_index,
  block_accordion_element: BlokkliComponent_accordion_element_index,
  block_chart: BlokkliComponent_chart_index,
  block_button: BlokkliComponent_button_index,
  block_contact_box: BlokkliComponent_contact_box_index,
  block_container: BlokkliComponent_container_index,
  block_editorial_title: BlokkliComponent_editorial_title_index,
  block_fact: BlokkliComponent_fact_index,
  block_grid: BlokkliComponent_grid_index,
  block_image: BlokkliComponent_image_index,
  block_link_box: BlokkliComponent_link_box_index,
  block_links: BlokkliComponent_links_index,
  block_mailxpert: BlokkliComponent_mailxpert_index,
  block_node_teaser: BlokkliComponent_node_teaser_index,
  block_person_box: BlokkliComponent_person_box_index,
  block_product_teaser: BlokkliComponent_product_teaser_index,
  block_section_title: BlokkliComponent_section_title_index,
  block_table: BlokkliComponent_table_index,
  block_table_row: BlokkliComponent_table_row_index,
  block_teaser_box: BlokkliComponent_teaser_box_index,
  block_text: BlokkliComponent_text_index,
  block_video_remote: BlokkliComponent_video_remote_index,
  block_blokkli_fragment: BlokkliComponent_blokkli_fragment_index
}

    
import BlokkliFragmentComponent_force_page_break from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/BlokkliFragment/ForcePageBreak/index.vue'
import BlokkliFragmentComponent_home_events_slider from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/BlokkliFragment/HomeEventsSlider/index.vue'
import BlokkliFragmentComponent_home_infoletter_list from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/BlokkliFragment/HomeInfoletterList/index.vue'
import BlokkliFragmentComponent_home_teasers from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/BlokkliFragment/HomeTeasers/index.vue'
import BlokkliFragmentComponent_number_highlights from '/builds/bazinga/pharmasuisse/frontend/components/Paragraph/BlokkliFragment/NumberHighlights/index.vue'

const globalFragments: Record<string, any> = {
  fragment_force_page_break: BlokkliFragmentComponent_force_page_break,
  fragment_home_events_slider: BlokkliFragmentComponent_home_events_slider,
  fragment_home_infoletter_list: BlokkliFragmentComponent_home_infoletter_list,
  fragment_home_teasers: BlokkliFragmentComponent_home_teasers,
  fragment_number_highlights: BlokkliFragmentComponent_number_highlights
}


const chunks: Record<string, () => Promise<any>> = {
  rare: () => import('#blokkli/chunk-rare')
}

const chunkMapping: Record<string, string> = {
  "block_frame": "rare",
  "block_view": "rare",
  "block_webform": "rare"
}

const fragmentChunkMapping: Record<string, string> = {}

export function getBlokkliItemComponent(bundle: string, fieldListType?: string, parentBundle?: string): any {
  const forFieldListType = 'block_' + bundle + '__field_list_type_' + fieldListType
  if (global[forFieldListType]) {
    return global[forFieldListType]
  }
  if (parentBundle) {
    const forParentBundle = 'block_' + bundle + '__parent_block_' + parentBundle
    if (global[forParentBundle]) {
      return global[forParentBundle]
    }
  }
  const key = 'block_' + bundle
  if (global[key]) {
    return global[key]
  }
  const chunkName = chunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}

export function getBlokkliFragmentComponent(name: string): any {
  const key = 'fragment_' + name
  if (globalFragments[key]) {
    return globalFragments[key]
  }
  const chunkName = fragmentChunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}
